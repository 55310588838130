<template>
	<div id="plannedShift">
		<jy-query ref="form" :model="formData">
			<jy-query-item prop="routeId" label="线路">
				<el-select v-model="formData.routeId">
					<el-option v-for="one in routeList" v-bind:key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item prop="vehicleNo" label="车辆">
				<el-input v-model="formData.vehicleNo"></el-input>
			</jy-query-item>
			<jy-query-item prop="driverNa" label="司机">
				<el-input v-model="formData.driverNa"></el-input>
			</jy-query-item>
			<jy-query-item prop="oDate" label="日期">
				<el-date-picker
					v-model="formData.oDate"
					:clearable="false"
					value-format="yyyy-MM-dd"
					type="date"
					placeholder="选择日期"
				></el-date-picker>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck" v-if="btnexist('scheduleSchedulingPlannedShiftList')">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')" v-if="btnexist('scheduleSchedulingPlannedShiftReset')"
					>重置</el-button
				>
				<!-- <el-row>
                </el-row>
                <el-row>
                </el-row> -->
			</template>
			<template slot="operate">
				<el-button type="primary" @click="addPlanShift()" v-if="btnexist('scheduleSchedulingPlannedShiftInsertPlan')"
					>新增计划班次</el-button
				>
				<el-button type="primary" @click="addUdate()" v-if="btnexist('scheduleSchedulingPlannedShiftInsert')">新增班次</el-button>
				<el-button type="primary" @click="importFun" v-if="btnexist('scheduleSchedulingPlannedShiftImport')">导入</el-button>
				<el-button type="primary" @click="rotationTable" v-if="btnexist('scheduleSchedulingPlannedShiftRotation')"
					>实际轮休情况</el-button
				>
			</template>
		</jy-query>
		<jy-table max-height="595" ref="jyTable" :data="dataShow">
			<jy-table-column type="index" width="60" label="序号" fixed="left" align="center"></jy-table-column>
			<!-- <jy-table-column type="selection" width="40" fixed="left"></jy-table-column> -->
			<jy-table-column prop="routeNa" label="所属线路" min-width="120" fixed="left"></jy-table-column>
			<jy-table-column prop="vehicleNo" label="车牌号" min-width="120" fixed="left"></jy-table-column>
			<jy-table-column prop="customNo" label="自编号" min-width="120" fixed="left"></jy-table-column>
			<!-- <jy-table-column prop="shiteNa" label="班次名称" min-width="200"></jy-table-column> -->
			<jy-table-column label="班次类型" min-width="100">
				<template slot-scope="scope">{{ scope.row.type | type }}</template>
			</jy-table-column>
			<jy-table-column prop="oDate" label="运营日期" min-width="100"></jy-table-column>
			<jy-table-column prop="dTime" label="计划发车时间" min-width="150"></jy-table-column>
			<jy-table-column prop="oTime" label="计划结束时间" min-width="150"></jy-table-column>
			<jy-table-column prop="sSiteNa" label="起始站名称" min-width="200"></jy-table-column>
			<jy-table-column prop="eSiteNa" label="终点站名称" min-width="200"></jy-table-column>
			<jy-table-column prop="driverNa" label="司机名称" min-width="200"></jy-table-column>
			<jy-table-column prop="workNum" label="机工号" min-width="100"></jy-table-column>
			<jy-table-column label="是否有效" min-width="100">
				<template slot-scope="scope">{{ scope.row.isActive | isActive }}</template>
			</jy-table-column>
			<jy-table-column label="失效原因" min-width="100">
				<template slot-scope="scope">{{ scope.row.reason | reason }}</template>
			</jy-table-column>
			<jy-table-column label="方案类型" min-width="100">
				<template slot-scope="scope">{{ scope.row.schemeT | schemeT }}</template>
			</jy-table-column>
			<jy-table-column label="是否跨线路" min-width="100">
				<template slot-scope="scope">{{ scope.row.isCrossL | isCrossL }}</template>
			</jy-table-column>
			<jy-table-column prop="remark" label="备注" min-width="150"></jy-table-column>
			<jy-operate v-bind:list="operationList" width="240"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
		<rotation-table ref="rotationTable"></rotation-table>
		<add-plan-shift ref="addPlanShift" @updateList="oncheck"></add-plan-shift>
		<add-shift ref="npm" @updateList="update"></add-shift>
		<jy-import
			ref="jyImport"
			:headers="uploadHeaders"
			:action="action"
			@downloadTemplate="downloadTemplate"
			@getElxData="getElxData"
			@confirm="confirm"
			:successLength="correctCount"
			:errList="listError"
			:status.sync="importStatus"
		></jy-import>
	</div>
</template>
<script>
import addPlanShift from "@/components/pages/admin/scheduling/plannedShift/addPlanShift";
import addShift from "@/components/pages/admin/scheduling/plannedShift/addShift";
import rotationTable from "@/components/pages/admin/scheduling/plannedShift/rotationTable";
import { btnMixins } from "@/common/js/button.mixin";
export default {
	data() {
		return {
			schemeId: "",

			formData: {
				routeId: "",
				vehicleNo: "",
				driverNa: "",
				oDate: ""
			},
			routeList: [],
			beginDate: "",
			endDate: "",
			chooseDate: "",
			list: [],
			driverList: [],
			operationList: [],
			rotationList: [],
			monthShift: [],
			isInit: true,
			// 分页
			pageIndex: 1,
			total: 0,
			pageSize: 10,
			pageNum: 1,
			totalPageData: [],
			dataShow: [],

			//导入
			uploadHeaders: {
				token: this.$util.getStor("token")
			},
			action: "/ischedule/waybillPlan/importSitesCach",
			importId: "",
			listError: [],
			correctCount: "",
			errorCount: "",

			importStatus: 1,

			btnMenuId: "scheduleSchedulingPlannedShift"
		};
	},
	mixins: [btnMixins],
	components: {
		addPlanShift,
		addShift,
		rotationTable
	},
	filters: {
		isActive(val) {
			if (val == 0) {
				return "无效";
			} else if (val == 1) {
				return "有效";
			}
		},
		reason(val) {
			let v = "";
			switch (val) {
				case "0":
					v = "正常";
					break;
				case "1":
					v = "砍班";
					break;
				case "2":
					v = "脱班";
					break;
				case "3":
					v = "调整";
					break;
			}
			return v;
		},
		type(val) {
			if (val == 1) {
				return "普通班";
			} else if (val == 2) {
				return "加班";
			} else if (val == 3) {
				return "夜班";
			} else {
				return val;
			}
		},
		schemeT(val) {
			if (val == 1) {
				return "上行";
			} else if (val == 2) {
				return "下行";
			}
		},
		isCrossL(val) {
			if (val == 1) {
				return "未跨线路";
			} else if (val == 2) {
				return "跨线路";
			}
		}
	},
	async created() {
		this.seOperation();
		this.getDate();
		await this.getRouteList();
		this.getList();
	},
	methods: {
		seOperation() {
			this.operationList = [
				{
					name: "发车通知",
					fun: item => {
						this.sedCar(item);
					},
					isShow: () => {
						return true;
					}
				},
				{
					name: "编辑",
					fun: item => {
						this.addUdate(item);
					},
					isShow: () => {
						return true;
					}
				},
				{
					name: "复制",
					fun: item => {
						this.copy(item);
					},
					isShow: () => {
						return true;
					}
				},
				{
					name: "删除",
					fun: item => {
						this.del(item);
					},
					isShow: () => {
						return true;
					}
				}
			];
		},
		async getRouteList() {
			let url = "/route/info/queryRouteInfoByOrgIds";
			await this.$http.post(url).then(({ detail }) => {
				this.routeList = detail;
				if (this.routeList.length) {
					this.formData.routeId = this.routeList[0].routeId;
				}
			});
		},
		getDate() {
			let now = new Date();
			let nowMonth = now.getMonth();
			let nowYear = now.getFullYear();
			this.formData.oDate = now.Format("yyyy-MM-dd");
			this.beginDate = new Date(nowYear, nowMonth, 1).Format("yyyy-MM-dd");
			this.endDate = new Date(nowYear, nowMonth + 1, 0).Format("yyyy-MM-dd");
		},
		getList() {
			let option = {
				...this.formData
			};
			this.pageIndex = 1;
			let url = "/waybillPlan/queryPage";
			this.$http.post(url, option).then(({ detail }) => {
				this.list = detail;
				this.total = this.list.length;
				this.calcPageData();
			});
			console.log(this.list);
		},
		// 查询表格
		oncheck() {
			this.isInit = true;
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			if (this.routeList.length) {
				this.formData.routeId = this.routeList[0].routeId;
			}
			this.getDate();
			this.oncheck();
		},
		// 分页
		calcPageData() {
			this.dataShow = [];
			this.totalPageData = [];
			if (this.list.length > 1) {
				this.pageNum = Math.ceil(this.list.length / this.pageSize) || 1;
			}
			for (let i = 0; i < this.total; i++) {
				this.totalPageData[i] = this.list.slice(this.pageSize * i, this.pageSize * (i + 1));
			}
			this.dataShow = this.totalPageData[this.pageIndex - 1];
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.calcPageData();
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.pageIndex = val;
			this.dataShow = this.totalPageData[val - 1];
		},
		addPlanShift() {
			this.$refs.addPlanShift.init();
		},
		addUdate(option) {
			if (option) {
				this.$refs.npm.init(option, "update");
			} else {
				let routeObj = this.routeList.filter(item => item.routeId == this.formData.routeId)[0];
				console.log(routeObj);
				let option = {
					routeId: routeObj.routeId,
					routeNa: routeObj.cName,
					orgId: routeObj.orgId,
					orgPath: routeObj.orgPath,
					oDate: this.formData.oDate,
					schemeId: this.schemeId
				};
				this.$refs.npm.init(option, "add");
			}
		},
		update(id) {
			if (id) {
				this.schemeId = id;
			}
			this.isInit = false;
			this.getList();
		},
		sedCar(item) {
			this.isInit = false;
			console.log(item);
			let url = "/waybillPlan/depNotice";
			let option = {
				waybillId: item.waybillId
			};
			this.$http.post(url, option).then(res => {
				this.$message({
					message: res.msg,
					type: "success"
				});
				this.getList();
			});
		},
		copy(item) {
			this.isInit = false;
			let url = "/waybillPlan/add";
			let option = {
				...item,
				schemeId: this.schemeId
			};
			this.$http.post(url, option).then(res => {
				this.$message({
					message: res.msg,
					type: "success"
				});
				this.getList();
			});
		},
		del(item) {
			this.isInit = false;
			this.$confirm("是否删除该班次?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let url = "/waybillPlan/del";
					let option = {
						waybillId: item.waybillId
					};
					this.$http.post(url, option).then(res => {
						this.$message({
							message: res.msg,
							type: "success"
						});
						this.getList();
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
		},
		async rotationTable() {
			await this.getRotationList();
			await this.getMonthAllshift();
			let driverShiftList = [];
			this.monthShift.forEach(item => {
				if (item.driverId && !driverShiftList.some(one => one.driverId == item.driverId)) {
					driverShiftList.push({
						driverId: item.driverId,
						driverNa: item.driverNa,
						workData: this.monthShift
							.filter(val => val.driverId == item.driverId)
							.map(val => {
								return {
									oDate: val.oDate,
									dTime: Number(val.dTime.split(":").join(""))
								};
							})
					});
				}
			});
			console.log(this.rotationList);
			driverShiftList.forEach(item => {
				let date = [];
				this.rotationList.forEach(one => {
					let wList = item.workData.filter(val => val.oDate == one.date);
					if (!wList.length) {
						date.push({
							date: one.date,
							typeName: "休"
						});
					} else {
						if (one.limitT) {
							let morning = !wList.some(val => val.dTime < one.limitT);
							let afternoon = !wList.some(val => val.dTime > one.limitT);
							if (morning) {
								date.push({
									date: one.date,
									typeName: "上午"
								});
							}
							if (afternoon) {
								date.push({
									date: one.date,
									typeName: "下午"
								});
							}
						}
					}
				});
				item.date = date;
			});

			let option = {
				beginDate: this.beginDate,
				endDate: this.endDate,
				driverList: driverShiftList
			};
			this.$refs.rotationTable.init(option);
		},
		async getRotationList() {
			let url = "/rotation/set/queryList";
			let option = {
				routeId: this.formData.routeId,
				startTime: this.beginDate,
				endTime: this.endDate
			};
			await this.$http.post(url, option).then(({ detail }) => {
				if (detail) {
					let dlist = this.$util.getBetweenDateList(this.beginDate, this.endDate);
					let newDList = dlist.map(one => {
						let obj = detail.filter(item => item.rotationPlanT == one.date)[0];
						return {
							date: one.date,
							limitT: obj ? Number(obj.limitT.split(":").join("")) : ""
						};
					});
					this.rotationList = newDList;
				} else {
					this.rotationList = [];
				}
			});
		},
		async getMonthAllshift() {
			let url = "/waybillPlan/queryListByMonth";
			let option = {
				routeId: this.formData.routeId,
				startTime: this.beginDate,
				endTime: this.endDate
			};
			await this.$http.post(url, option).then(({ detail }) => {
				if (detail) {
					this.monthShift = detail;
				} else {
					this.monthShift = [];
				}
			});
		},

		//导入
		importFun() {
			this.$refs.jyImport.init();
		},
		downloadTemplate() {
			let url = "/waybillPlan/downloadTemplate";
			this.$http.post(url, {}, { type: "format", isExcel: true }).then(res => {
				this.$util.funDownload(res.data, "计划班次模板");
			});
		},
		getElxData(data) {
			console.log(data.detail);
			this.importId = data.detail.importId;
			let url = "/waybillPlan/importSitesCheck";
			let params = {
				importId: data.detail.importId
			};
			this.$http.post(url, params).then(data => {
				let { detail } = data;
				this.listError = detail.listError;
				this.correctCount = detail.correctCount;
				this.errorCount = detail.errorCount;
			});
		},
		confirm() {
			let params = {
				importId: this.importId
			};
			let url = "/waybillPlan/importSitesData";
			this.$http.post(url, params).then(
				data => {
					if (data.code == 0) {
						this.importStatus = 1;
						this.oncheck();
					}
				},
				() => {
					this.importStatus = -1;
				}
			);
		}
	}
};
</script>
<style lang="scss" scoped="plannedShift">
#plannedShift {
}
</style>
